import axios from "axios";
const getApuntesAreas = async (asignatura) => {
  const data = new FormData();
  data.append("action", "getArea");
  data.append("asignatura", asignatura);
  return axios
    .post("https://edootek.com/wp-admin/admin-ajax.php", data, {
      headers: {
        Accept: "*/*",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
};

const getApuntes = async (asignatura, area) => {
  //Recuperar apuntes
  const data = new FormData();
  data.append("action", "get_apuntes");
  data.append("asignatura", asignatura);
  data.append("area", area);
  return axios
    .post("https://edootek.com/wp-admin/admin-ajax.php", data, {
      headers: {
        Accept: "*/*",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
};

const recuperarVideos = async (asignatura, curso) => {
  const data = new FormData();
  data.append("action", "get_videos");
  data.append("asignatura", asignatura);
  data.append("curso", curso);
  return axios
    .post("https://edootek.com/wp-admin/admin-ajax.php", data, {
      headers: {
        Accept: "*/*",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
};

const recuperarLectoraAjax = async (nivel) => {
  const data = new FormData();
  data.append("action", "items_lectora_ajax");
  data.append("nivel", nivel);

  return axios
    .post("https://edootek.com/wp-admin/admin-ajax.php", data, {
      headers: {
        Accept: "*/*",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
  return [];
};

const recuperarReadingAjax = async (contenido) => {
  const data = new FormData();
  data.append("action", "items_reading_ajax");
  data.append("contenido", contenido);

  return axios
    .post("https://edootek.com/wp-admin/admin-ajax.php", data, {
      headers: {
        Accept: "*/*",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
  return [];
};

const recuperarCuentosAjax = async (tipo) => {
  const data = new FormData();
  data.append("action", "items_cuentos_ajax");
  data.append("tipo", tipo);

  return axios
    .post("https://edootek.com/wp-admin/admin-ajax.php", data, {
      headers: {
        Accept: "*/*",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
  return [];
};

const recuperarFunciones = async (curso, area) => {
  const data = new FormData();
  data.append("action", "items_funciones_ajax");
  data.append("curso", curso);
  data.append("area", area);
  return axios
    .post("https://edootek.com/wp-admin/admin-ajax.php", data, {
      headers: {
        Accept: "*/*",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
  return [];
};
export {
  getApuntesAreas,
  getApuntes,
  recuperarVideos,
  recuperarLectoraAjax,
  recuperarReadingAjax,
  recuperarCuentosAjax,
  recuperarFunciones,
};
