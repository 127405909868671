import { useState, useContext } from "react";
import {
  SafeAreaView,
  View,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  Image,
} from "react-native";
import {
  useFonts,
  Grandstander_400Regular,
} from "@expo-google-fonts/grandstander";
import axios from "axios";
import { ContextoGeneral } from "../data/context";
import AsyncStorage from "@react-native-async-storage/async-storage";

const Login = ({ route }) => {
  const [logueado, setLogueado] = useState(false);
  const [error, setError] = useState({ error: false, mensaje: "" });
  const [clave, setClave] = useState("");
  const [usuario, setUsuario] = useState("");
  const usuarioActual = useContext(ContextoGeneral);

  let [fontsLoaded, fontError] = useFonts({
    Grandstander_400Regular,
  });

  if (!fontsLoaded && !fontError) {
    return null;
  }

  const estilo = StyleSheet.create({
    container: {
      backgroundColor: "#CF386D",
      margin: 0,
      flex: 1,

      alignItems: "center",
    },

    login: {
      backgroundColor: "#fff",
      borderWidth: 2,
      borderColor: "#CF386D",
      borderRadius: 10,
      justifyContent: "center",
      alignContent: "center",
      color: "#CF386D",
      fontFamily: "Grandstander_400Regular",
      fontSize: 18,
      width: "80%",
      height: 250,
      padding: 10,
    },

    texto: {
      color: "#CF386D",
      fontFamily: "Grandstander_400Regular",
      textAlign: "center",
    },

    input: {
      width: "90%",
      height: 40,
      margin: 10,
      borderWidth: 2,
      borderColor: "#60acac",
      color: "#60acac",
      fontFamily: "Grandstander_400Regular",
      padding: 10,
      fontSize: 20,
    },
    mensajes: {
      backgroundColor: "#ffcc66",
      color: "black",
      fontSize: 16,
      padding: 10,
      marginTop: 10,
    },
    boton: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: "#CF386D",
      fontSize: 18,
      width: 200,
      paddingVertical: 10,
      paddingHorizontal: 20,
      borderRadius: 15,
      color: "#ffffff",
      fontFamily: "Grandstander_400Regular",
      margin: 10,
    },
  });

  const hacerLogin = () => {
    if (usuario.length < 1) {
      setError({ error: true, mensaje: "Debe ingresar un  usuario válido" });
      return;
    }

    if (clave.length < 1) {
      setError({ error: true, mensaje: "Debe ingresar una contraseña" });
      return;
    }
    setError({ error: false, mensaje: "" });
    let datos = new FormData();
    datos.append("action", "valida_login");
    datos.append("es_estudiante", "1");
    datos.append("email", usuario);
    datos.append("clave", clave);

    axios
      .post("https://edootek.com/wp-admin/admin-ajax.php", datos, {
        headers: {
          Accept: "*/*",
        },
      })
      .then((response) => {
        if (response.data.data.estado == "ok") {
          route.params.setAlumno({
            id: response.data.data.id_usuario,
            nombre: response.data.data.nombre,
            id_perfil: response.data.data.id_perfil,
            avatar: response.data.data.avatar,
          });
          AsyncStorage.setItem(
            "usuario",
            JSON.stringify({
              id: response.data.data.id_usuario,
              nombre: response.data.data.nombre,
              id_perfil: response.data.data.id_perfil,
              avatar: response.data.data.avatar,
            })
          );
        } else {
          setError({ error: true, mensaje: response.data.data.msg });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <SafeAreaView style={estilo.container}>
      <Image
        source={{
          uri: "https://edootek.com/wp-content/uploads/2023/08/monster-1-912x1024.png",
        }}
        style={{ width: 250, height: 250, marginBottom: 20 }}
      />
      <View style={estilo.login}>
        <Text style={estilo.texto}>Nombre de usuario del alumno(*)</Text>
        <TextInput
          style={estilo.input}
          value={usuario}
          onChangeText={setUsuario}
        />
        <Text style={estilo.texto}>Contraseña(*)</Text>
        <TextInput
          style={estilo.input}
          value={clave}
          onChangeText={setClave}
          secureTextEntry={true}
        />
        <TouchableOpacity
          style={{ justifyContent: "center", alignItems: "center" }}
          onPress={hacerLogin}
        >
          <Text style={estilo.boton}>Iniciar sesión</Text>
        </TouchableOpacity>
      </View>
      <View>
        {error.error ? (
          <Text style={estilo.mensajes}>{error.mensaje}</Text>
        ) : (
          <Text></Text>
        )}
      </View>
      <Image
        source={{
          uri: "https://edootek.com/wp-content/uploads/2023/08/logo-edootek-blanco.png",
        }}
        style={{ width: 300, height: 112, marginTop: 40 }}
      />
    </SafeAreaView>
  );
};

export default Login;
