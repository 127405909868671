import { View, Image, Text } from "react-native";
const Post = (props: { titulo: string; imagen: string }) => {
  let style = {};
  if (props.imagen.includes("212x300")) {
    style = { width: 159, height: 226 };
  } else {
    style = { width: 226, height: 159 };
  }
  return (
    <View style={{ margin: 10 }}>
      <Text
        style={{
          width: "100%",
          textAlign: "center",
          color: "#CF386D",
          margin: 5,
        }}
      >
        {props.titulo}
      </Text>
      <View style={{ borderColor: "#CF386D", borderWidth: 0, padding: 10 }}>
        <Image source={{ uri: props.imagen }} style={style} />
      </View>
    </View>
  );
};

export default Post;
