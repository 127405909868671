import {
  View,
  Image,
  Text,
  Pressable,
  Modal,
  Button,
  ScrollView,
} from "react-native";
import { useContext, useEffect, useState } from "react";
import { ContextoGeneral } from "../data/context";
import Avatar from "../components/avatar";
const Perfil = ({ route, navigation }) => {
  const [verAvatares, setVerAvatares] = useState(false);
  const usuarioActual = route.params.alumno;
  const avatares = [];
  const [avatarActual, setAvatarActual] = useState(route.params.alumno.avatar);
  const cambiarAvatar = (i: number, tipo: string) => {
    setAvatarActual(
      "https://edootek.com/wp-content/themes/wp-bootstrap-starter-child/imagenes/avatares/" +
        tipo +
        "_" +
        i +
        ".png"
    );
    setVerAvatares(false);
  };
  for (let i = 1; i < 19; i++) {
    avatares.push(
      <Pressable
        key={i}
        onPress={() => {
          cambiarAvatar(i, "h");
        }}
      >
        <Avatar key={i} id={i} tipo="h" />
      </Pressable>
    );
    avatares.push(
      <Pressable
        key={i * 20}
        onPress={() => {
          cambiarAvatar(i, "m");
        }}
      >
        <Avatar key={i * 20} id={i} tipo="m" />
      </Pressable>
    );
  }
  return (
    <View>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          marginTop: 40,
        }}
      >
        <Pressable
          onPress={() => {
            setVerAvatares(true);
          }}
        >
          <Image
            source={{ uri: avatarActual }}
            style={{ width: 200, height: 200 }}
          />
        </Pressable>
        <Text style={{ fontSize: 40 }}>{usuarioActual.nombre}</Text>
      </View>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          marginTop: 22,
        }}
      >
        <Modal
          visible={verAvatares}
          transparent={true}
          animationType="slide"
          style={{ margin: "auto" }}
        >
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: 60,
              padding: 20,
            }}
          >
            <View
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                flexWrap: "wrap",
                backgroundColor: "white",
                width: "80%",
                margin: "auto",
                height: 650,
                borderRadius: 15,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 4,
                elevation: 5,
                gap: 30,
                padding: 20,
              }}
            >
              <Text>Escoge tu avatar</Text>
              <ScrollView
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                    flexDirection: "column",
                    gap: 20,
                  }}
                >
                  {avatares}
                </View>
              </ScrollView>
              <Button
                onPress={() => {
                  setVerAvatares(false);
                }}
                title="Cerrar"
                color="steelblue"
              />
            </View>
          </View>
        </Modal>
      </View>
    </View>
  );
};

export default Perfil;
