import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import Home from "./pantallas/home";
import "./assets/style";
import { Image } from "react-native";
import Asignatura from "./pantallas/asignatura";
import Curso from "./pantallas/curso";
import Buscar from "./pantallas/buscar";
import Area from "./pantallas/area";
import Login from "./pantallas/login";
import Contenido from "./pantallas/contenido";
import Recurso from "./pantallas/recurso";
import Calculo from "./pantallas/calculo";
import Menu from "./components/menuInferior";
import Perfil from "./pantallas/perfil.tsx";
import Apuntes from "./pantallas/apuntes";
import Posts from "./pantallas/posts";
import Pagina from "./pantallas/pagina";
import Videos from "./pantallas/videos";
import Comprension from "./pantallas/comprension";
import Reading from "./pantallas/reading";
import Cuentos from "./pantallas/cuentos";
import Ejecutivas from "./pantallas/ejecutivas";
import { ContextoGeneral } from "./data/context";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function App() {
  const [navegacion, setNavegacion] = useState([]);
  const [alumno, setAlumno] = useState({ id: 0 });
  const [cargado, setCargado] = useState(false);

  const readUsuario = async () => {
    const valor = await AsyncStorage.getItem("usuario");
    if (valor !== null) {
      setAlumno(JSON.parse(valor));
    }
  };

  useEffect(() => {
    if (!cargado) {
      let accion = new FormData();
      accion.append("action", "get_niveles");
      axios
        .post("https://edootek.com/wp-admin/admin-ajax.php", accion, {
          headers: {
            Accept: "*/*",
          },
        })
        .then((response) => {
          setNavegacion(response.data.data);
          readUsuario();
          setCargado(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  const LogoImage = () => {
    return (
      <Image
        source={{
          uri: "https://edootek.com/wp-content/uploads/2023/08/logo-edootek-blanco.png",
        }}
      />
    );
  };
  const estiloHeader = (visible) => {
    return {
      headerStyle: { backgroundColor: "#CF386D" },
      headerTintColor: "white",
      headerTitleAlign: "center",
      headerBackVisible: visible,
    };
  };
  const Stack = createNativeStackNavigator();
  const Tab = createBottomTabNavigator();
  if (alumno.id == 0) {
    return (
      <ContextoGeneral.Provider value={{ alumno }}>
        <NavigationContainer>
          <Stack.Navigator>
            <Stack.Screen
              name="Login"
              component={Login}
              options={estiloHeader}
              initialParams={{ setAlumno: setAlumno }}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </ContextoGeneral.Provider>
    );
  } else {
    return (
      <ContextoGeneral.Provider value={{ alumno }}>
        <NavigationContainer>
          <Stack.Navigator initialRouteName="inicio">
            <Stack.Screen
              name="Inicio"
              component={Home}
              options={estiloHeader(false)}
              initialParams={{ arbol: { navegacion } }}
            />
            <Stack.Screen
              name="Buscar"
              component={Buscar}
              options={estiloHeader}
            />
            <Stack.Screen
              name="Asignatura"
              component={Asignatura}
              options={estiloHeader(true)}
              initialParams={{ arbol: { navegacion } }}
            />
            <Stack.Screen
              name="Curso"
              component={Curso}
              options={estiloHeader(true)}
              initialParams={{ arbol: { navegacion } }}
            />
            <Stack.Screen
              name="Area"
              component={Area}
              options={estiloHeader(true)}
              initialParams={{ arbol: { navegacion } }}
            />
            <Stack.Screen
              name="Contenido"
              component={Contenido}
              options={estiloHeader(true)}
              initialParams={{ arbol: { navegacion } }}
            />
            <Stack.Screen
              name="Recurso"
              component={Recurso}
              options={estiloHeader(true)}
              initialParams={{ arbol: { navegacion } }}
            />
            <Stack.Screen
              name="Calculo"
              component={Calculo}
              options={estiloHeader(true)}
              initialParams={{ arbol: { navegacion } }}
            />
            <Stack.Screen
              name="Perfil"
              component={Perfil}
              options={estiloHeader(false)}
              initialParams={{ alumno: alumno }}
            />
            <Stack.Screen
              name="Apuntes"
              component={Apuntes}
              options={estiloHeader(false)}
              initialParams={{ alumno: alumno }}
            />
            <Stack.Screen
              name="Posts"
              component={Posts}
              options={estiloHeader(false)}
              initialParams={{ alumno: alumno }}
            />
            <Stack.Screen
              name="Pagina"
              component={Pagina}
              options={estiloHeader(false)}
              initialParams={{ alumno: alumno }}
            />
            <Stack.Screen
              name="Videos"
              component={Videos}
              options={estiloHeader(false)}
              initialParams={{ alumno: alumno }}
            />
            <Stack.Screen
              name="Comprension"
              component={Comprension}
              options={estiloHeader(false)}
              initialParams={{ alumno: alumno }}
            />
            <Stack.Screen
              name="Reading"
              component={Reading}
              options={estiloHeader(false)}
              initialParams={{ alumno: alumno }}
            />
            <Stack.Screen
              name="Cuentos"
              component={Cuentos}
              options={estiloHeader(false)}
              initialParams={{ alumno: alumno }}
            />
            <Stack.Screen
              name="Ejecutivas"
              component={Ejecutivas}
              options={estiloHeader(false)}
              initialParams={{ alumno: alumno }}
            />
          </Stack.Navigator>
          <Menu pagina1="Inicio" pagina2="Perfil" pagina3="Perfil" />
        </NavigationContainer>
      </ContextoGeneral.Provider>
    );
  }
}
