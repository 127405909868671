import {
  ScrollView,
  SafeAreaView,
  View,
  TouchableOpacity,
  Text,
  Button,
  Image,
} from "react-native";
import Boton from "../components/boton";
import { useState } from "react";
import global from "../assets/style";

function Asignatura({ route, navigation }) {
  const [recurso, setRecurso] = useState(route.params.identificador);
  const [botones, setBotones] = useState(
    route.params.botones[recurso].asignaturas
  );
  const tipo = route.params.botones[recurso].nombre;
  navigation.setOptions({ title: tipo });
  const buscar =
    tipo == "Apuntes" || tipo == "Videos" || tipo == "Funciones ejecutivas"
      ? "none"
      : "flex";

  return (
    <SafeAreaView>
      <ScrollView>
        <View
          style={{
            display: buscar,
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f2f2f2",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("Buscar", {
                asignaturas: botones,
                recurso: route.params.recurso,
              });
            }}
          >
            <Boton
              imagen="https://edootek.com/wp-content/themes/wp-bootstrap-starter-child/imagenes/lupa_buscar.png"
              texto="Buscar"
              identificador="111"
              recurso="000"
            ></Boton>
          </TouchableOpacity>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {botones.map(
            (
              item: { nombre: string; identificador: string; logo: string },
              index: number
            ) => {
              return (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    if (tipo == "Apuntes") {
                      navigation.navigate("Apuntes", {
                        asignatura: item.identificador,
                        titulo: item.nombre,
                      });
                    } else
                      navigation.navigate("Curso", {
                        botones: botones,
                        identificador: recurso,
                        asignatura: index,
                      });
                  }}
                  style={{ backgroundColor: "none" }}
                >
                  <Boton
                    imagen={item.logo}
                    texto={item.nombre}
                    identificador={item.identificador}
                    recurso={item.identificador}
                  ></Boton>
                </TouchableOpacity>
              );
            }
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export default Asignatura;
