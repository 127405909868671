import { useState } from "react";
import Boton from "../components/boton";
import Post from "../components/post";
import { ScrollView, SafeAreaView, View, Pressable, Text } from "react-native";
import { recuperarCuentosAjax } from "../data/consultas";
import Cargando from "../components/placeHolder";
function Cuentos({ route, navigation }) {
  navigation.setOptions({ title: "Cuentos con valores" });
  const [niveles, setNiveles] = useState([
    {
      nombre: "Cuentos clásicos",
      icono: "https://edootek.com/wp-content/uploads/2023/08/clasicos.png",
      identificador: "A13",
    },
    {
      nombre: "Cuentos populares",
      icono: "https://edootek.com/wp-content/uploads/2023/08/populares.png",
      identificador: "A14",
    },
    {
      nombre: "Mitos y leyendas",
      icono: "https://edootek.com/wp-content/uploads/2023/08/leyendas.png",
      identificador: "A15",
    },
    {
      nombre: "Fábulas",
      icono: "https://edootek.com/wp-content/uploads/2023/08/fabulas.png",
      identificador: "A16",
    },
  ]);

  const [paginas, setPaginas] = useState([]);
  const [cargando, setCargando] = useState(false);

  const recuperarCuentos = async (tipo: string) => {
    const items = await recuperarCuentosAjax(tipo);
    setCargando(false);
    setPaginas(items);
  };

  if (cargando) {
    return (
      <SafeAreaView>
        <Cargando />
      </SafeAreaView>
    );
  }
  if (paginas.length > 0)
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: 150,
          backgroundColor: "#f2f2f2",
        }}
      >
        <Pressable
          onPress={() => {
            setPaginas([]);
          }}
          style={{ margin: 10, width: "100%" }}
        >
          <Text
            style={{
              fontFamily: "Grandstander_400Regular",
              color: "#448799",
              width: "100%",
              textAlign: "center",
              margin: 10,
            }}
          >
            ← Otros tipos de cuento
          </Text>
        </Pressable>
        {paginas.map((pagina) => {
          return (
            <Pressable
              onPress={() => {
                navigation.navigate("Pagina", {
                  url: pagina.url + "?type=scorm",
                  titulo: pagina.post_title,
                });
              }}
            >
              <Boton
                imagen={pagina.imagen}
                texto={pagina.post_title}
                identificador="111"
                recurso="000"
              />
            </Pressable>
          );
        })}
      </View>
    );
  else
    return (
      <SafeAreaView>
        <ScrollView>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 120,
              backgroundColor: "#f2f2f2",
            }}
          >
            {niveles.map((item, index) => {
              return (
                <Pressable
                  key={index}
                  onPress={() => {
                    setCargando(true);
                    recuperarCuentos(item.identificador);
                  }}
                >
                  <Boton
                    imagen={item.icono}
                    texto={item.nombre}
                    identificador="111"
                    recurso="000"
                  />
                </Pressable>
              );
            })}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
}
export default Cuentos;
