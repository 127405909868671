import {
  ScrollView,
  SafeAreaView,
  View,
  TouchableOpacity,
  Text,
  TextInput,
} from "react-native";
import Boton from "../components/boton";
import { useEffect, useState } from "react";
import {
  useFonts,
  Grandstander_400Regular,
} from "@expo-google-fonts/grandstander";
import RadioButton from "../components/radio";
import Cargando from "../components/placeHolder";
import axios from "axios";
const Buscar = ({ route, navigation }) => {
  const [resultados, setResultados] = useState([]);
  const [asignaturas, setAsignaturas] = useState(route.params.asignaturas);
  const [seleccionado, setSeleccionado] = useState(-1);
  const [asignatura, setAsignatura] = useState("");
  const [recurso, setRecurso] = useState(route.params.recurso);
  const [buscando, setBuscando] = useState(false);
  const [buscar, setBuscar] = useState("");

  const seleccionar = (valor: number, asignatura: string) => {
    setSeleccionado(valor);
    setAsignatura(asignatura);
  };

  let [fontsLoaded, fontError] = useFonts({
    Grandstander_400Regular,
  });

  if (!fontsLoaded && !fontError) {
    return null;
  }

  const hacerBusqueda = () => {
    if (asignatura.length < 1) {
      alert("Debes escoger una asignatura");
      return;
    }
    setBuscando(true);
    let datos = new FormData();
    datos.append("action", "buscar_ajax");
    datos.append("recurso", "JUEGO");
    datos.append("asignatura", asignatura);
    datos.append("buscar", buscar);

    axios
      .post("https://edootek.com/wp-admin/admin-ajax.php", datos, {
        headers: {
          Accept: "*/*",
        },
      })
      .then((response) => {
        setResultados(response.data.data);
        setBuscando(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <SafeAreaView>
      <ScrollView>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            margin: 20,
          }}
        >
          <Text style={{ color: "#60acac", fontSize: 18 }}>
            Puedes buscar por contenido especifico
          </Text>
          <Text
            style={{
              color: "red",
              fontSize: 20,
              fontFamily: "Grandstander_400Regular",
              textTransform: "uppercase",
              fontWeight: "600",
            }}
          >
            ¿Que contenido buscas?
          </Text>
          <TextInput
            placeholder="Escribe aquí tu busqueda"
            style={{
              width: "90%",
              height: 40,
              margin: 10,
              borderWidth: 2,
              borderColor: "#60acac",
              color: "#60acac",
              fontFamily: "Grandstander_400Regular",
              padding: 10,
              fontSize: 20,
            }}
            value={buscar}
            onChangeText={setBuscar}
          />
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            margin: 0,
          }}
        >
          <TouchableOpacity
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              padding: 10,
            }}
            onPress={() => {
              hacerBusqueda();
            }}
          >
            <Text
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: "#CF386D",
                fontSize: 18,
                width: 120,
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderRadius: 15,
                color: "#ffffff",
                fontFamily: "Grandstander_400Regular",
              }}
            >
              Buscar
            </Text>
          </TouchableOpacity>
        </View>
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {asignaturas.map(
            (
              asignatura: { nombre: string; identificador: string },
              index: number
            ) => {
              return (
                <TouchableOpacity
                  key={index}
                  style={{ width: 150 }}
                  onPress={() => {
                    seleccionar(index, asignatura.identificador);
                  }}
                >
                  {seleccionado == index ? (
                    <RadioButton
                      selected={true}
                      texto={asignatura.nombre}
                    ></RadioButton>
                  ) : (
                    <RadioButton
                      selected={false}
                      texto={asignatura.nombre}
                    ></RadioButton>
                  )}
                </TouchableOpacity>
              );
            }
          )}
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-around",
            alignItems: "center",
            margin: 20,
          }}
        >
          {buscando && resultados.length < 1 ? (
            <Cargando />
          ) : resultados.length > 0 ? (
            resultados.map((item, index) => {
              return (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    navigation.navigate("Recurso", {
                      contenido: item.identificador,
                      titulo: item.nombre,
                    });
                  }}
                >
                  <View
                    style={{
                      backgroundColor: "#edf9fc",
                      borderRadius: 10,
                      marginLeft: 40,
                      marginRight: 40,
                      marginBottom: 10,
                      marginTop: 10,
                      padding: 10,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      borderColor: "#44879980",
                      borderWidth: 2,
                      borderStyle: "solid",
                    }}
                  >
                    <View
                      style={{
                        borderRadius: 25,
                        width: 50,
                        height: 50,
                        minWidth: 50,
                        backgroundColor: item.color,
                        marginRight: 10,
                      }}
                    ></View>
                    <Text
                      style={{
                        fontFamily: "Grandstander_400Regular",
                        color: "#448799",
                        width: "80%",
                      }}
                    >
                      {item.nombre}
                    </Text>
                  </View>
                </TouchableOpacity>
              );
            })
          ) : (
            <Text>No encontramos nada para tu busqueda.</Text>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default Buscar;
