import { recuperarVideos } from "../data/consultas";
import { useEffect, useState } from "react";
import { SafeAreaView, ScrollView, View, Pressable } from "react-native";
import Post from "../components/post";
import Cargando from "../components/placeHolder";
function Videos({ route, navigation }) {
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    const getLista = async () => {
      const data = await recuperarVideos(
        route.params.asignatura,
        route.params.curso
      );
      setVideos(data.data);
    };
    getLista();
  }, []);
  navigation.setOptions({ title: route.params.titulo });
  if (videos.length > 0) {
    return (
      <SafeAreaView>
        <ScrollView>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 80,
              backgroundColor: "#f2f2f2",
            }}
          >
            {videos.map((item) => {
              return (
                <Pressable
                  onPress={() => {
                    navigation.navigate("Pagina", {
                      url: item.guid + "?type=scorm",
                      titulo: item.titulo,
                    });
                  }}
                >
                  <Post titulo={item.titulo} imagen={item.imagen}></Post>
                </Pressable>
              );
            })}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  } else {
    return (
      <SafeAreaView>
        <Cargando />
      </SafeAreaView>
    );
  }
}
export default Videos;
