import { SafeAreaView } from "react-native-safe-area-context";
import { getApuntesAreas } from "../data/consultas";
import { useState, useEffect } from "react";
import { Pressable, ScrollView, Text, View } from "react-native";
import Boton from "../components/boton";
import Cargando from "../components/placeHolder";

function Apuntes({ route, navigation }) {
  const [apuntes, setApuntes] = useState([]);
  const [areas, setAreas] = useState([]);
  navigation.setOptions({ title: route.params.titulo });
  useEffect(() => {
    const recuperar = async () => {
      const data = await getApuntesAreas(route.params.asignatura);
      setAreas(data);
    };
    recuperar();
  }, areas);

  if (areas.length > 0)
    return (
      <SafeAreaView>
        <ScrollView>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {areas.map(
              (item: {
                nombre: string;
                identificador: string;
                logo: string;
              }) => {
                return (
                  <Pressable
                    onPress={() => {
                      navigation.navigate("Posts", {
                        asignatura: route.params.asignatura,
                        area: item.identificador,
                        titulo: item.nombre,
                      });
                    }}
                  >
                    <Boton
                      texto={item.nombre}
                      identificador={item.identificador}
                      imagen={item.logo}
                      recurso="000"
                    ></Boton>
                  </Pressable>
                );
              }
            )}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  else
    return (
      <SafeAreaView>
        <Cargando />
      </SafeAreaView>
    );
}

export default Apuntes;
