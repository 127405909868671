import { Image } from "react-native";
const Avatar = (props: { id: number; tipo: string }) => {
  const url = `https://edootek.com/wp-content/themes/wp-bootstrap-starter-child/imagenes/avatares/${props.tipo}_${props.id}.png`;

  return (
    <Image
      source={{
        uri: url,
      }}
      style={{ width: 100, height: 100 }}
    />
  );
};

export default Avatar;
