import { getApuntes } from "../data/consultas";
import { useEffect, useState } from "react";
import { Pressable, SafeAreaView, ScrollView, View } from "react-native";
import Cargando from "../components/placeHolder";
import Post from "../components/post";
function Posts({ route, navigation }) {
  const [posts, setPosts] = useState([]);

  const recuperarApuntes = async (asignatura: string, area: string) => {
    const apuntes = await getApuntes(asignatura, area);
    setPosts(apuntes.data);
  };

  useEffect(() => {
    recuperarApuntes(route.params.asignatura, route.params.area);
  }, []);
  navigation.setOptions({ title: route.params.titulo });
  if (posts.length > 0) {
    return (
      <SafeAreaView>
        <ScrollView>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 80,
              backgroundColor: "#f2f2f2",
            }}
          >
            {posts.map((item) => {
              return (
                <Pressable
                  onPress={() => {
                    navigation.navigate("Pagina", {
                      url: item.guid + "?type=scorm",
                      titulo: item.titulo,
                    });
                  }}
                >
                  <Post titulo={item.titulo} imagen={item.imagen}></Post>
                </Pressable>
              );
            })}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  } else {
    return (
      <SafeAreaView>
        <Cargando />
      </SafeAreaView>
    );
  }
}

export default Posts;
