import { useState } from "react";
import Cargando from "../components/placeHolder";
import ItemWeb from "./item";
import axios from "axios";
import { View, TouchableOpacity, Text, StyleSheet } from "react-native";

const estilos = StyleSheet.create({
  etiqueta: {
    maxWidth: 100,
    backgroundColor: "#f7fffe",
    borderColor: "#6cc5bc",
    borderWidth: 2,
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
  contenedor: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 10,
    margin: 20,
  },
});
const Recurso = ({ route, navigation }) => {
  const contenido = route.params.contenido;
  const [recursos, setRecursos] = useState([]);
  const [destino, setDestino] = useState("");
  const [activo, setActivo] = useState(0);

  const cu = route.params.curso;
  const ar = route.params.area;
  const as = route.params.asignatura;
  const tipo = route.params.tipo;

  navigation.setOptions({
    title: route.params.titulo.substring(0, 15) + "...",
    HeaderTitleStyle: { width: 50 },
  });

  const setVisto = (i: number) => {
    recursos[i].visitado = true;
  };

  if (tipo == "CUESTIONARIO") {
    return (
      <View style={{ height: "100%" }}>
        <ItemWeb
          gui={
            "https://edootek.com/cuestionarios-externos/?as=" +
            as +
            "&cu=" +
            cu +
            "&ar=" +
            ar +
            "&co=" +
            contenido +
            "&type=scorm"
          }
          scroll={true}
        />
      </View>
    );
  } else {
    if (recursos.length < 1) {
      axios
        .get(
          "https://edootek.com/wp-admin/admin-ajax.php?action=get_recursos&contenido=" +
            contenido
        )
        .then((result) => {
          setRecursos(result.data.data);
          setDestino(result.data.data[0].guid + "?type=scorm");
        })
        .catch((e) => {
          console.log(e);
        });
    }

    if (recursos.length < 1) {
      return <Cargando />;
    } else {
      return (
        <>
          <View style={estilos.contenedor}>
            {recursos.map((item, index) => {
              return (
                <TouchableOpacity
                  key={index}
                  onPress={(e) => {
                    setDestino(item.guid + "?type=scorm");
                    setVisto(index);
                  }}
                  onPressOut={(e) => {
                    setDestino(item.guid + "?type=scorm");
                  }}
                >
                  <View style={estilos.etiqueta}>
                    <View
                      id={"boton_" + index}
                      style={{
                        borderRadius: 10,
                        width: 20,
                        height: 20,
                        minWidth: 20,
                        backgroundColor:
                          index == 0 || item?.visitado == true
                            ? "#5fa35f"
                            : "#cc3745",
                        marginRight: 10,
                      }}
                    ></View>
                    <Text>{index + 1}</Text>
                  </View>
                </TouchableOpacity>
              );
            })}
          </View>
          <View style={{ height: 700 }}>
            <ItemWeb gui={destino} />
          </View>
        </>
      );
    }
  }
};

export default Recurso;
