import { Image, Text, View } from "react-native";
import global from "../assets/style";
import {
  useFonts,
  Grandstander_400Regular,
} from "@expo-google-fonts/grandstander";
import { useState } from "react";

const Boton = (props: {
  imagen: string;
  texto: string;
  identificador: string;
  recurso: string;
}) => {
  const [recurso, setCategorias] = useState(props.recurso);
  let [fontsLoaded, fontError] = useFonts({
    Grandstander_400Regular,
  });

  if (!fontsLoaded && !fontError) {
    return null;
  }

  return (
    <View style={global.boton}>
      <View style={global.botonImagenContainer}>
        <Image source={{ uri: props.imagen }} style={global.imagenBoton} />
      </View>
      <View>
        <Text
          style={{
            fontFamily: "Grandstander_400Regular",
            overflow: "visible",
            display: "flex",
            maxWidth: 170,
            textAlign: "center",
            height: 30,
          }}
        >
          {props.texto}
        </Text>
      </View>
    </View>
  );
};

export default Boton;
