import {
  View,
  Text,
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { useState } from "react";
import Boton from "../components/boton";
const Area = ({ route, navigation }) => {
  const [botones, setBotones] = useState(
    route.params.arbol.navegacion[route.params.identificador].asignaturas[
      route.params.asignatura
    ].cursos[route.params.curso].areas
  );
  navigation.setOptions({
    title:
      route.params.arbol.navegacion[route.params.identificador].asignaturas[
        route.params.asignatura
      ].cursos[route.params.curso].nombre,
  });

  const recurso =
    route.params.arbol.navegacion[route.params.identificador].nombre;

  return (
    <SafeAreaView>
      <ScrollView>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f2f2f2",
          }}
        >
          {botones.map(
            (
              item: {
                nombre: string;
                identificador: string;
                logo: string;
                identificador_mp_categoria_relacionada: string;
              },
              index: number
            ) => {
              return (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    if (recurso == "Funciones ejecutivas") {
                      navigation.navigate("Ejecutivas", {
                        curso:
                          route.params.arbol.navegacion[
                            route.params.identificador
                          ].asignaturas[route.params.asignatura].cursos[
                            route.params.curso
                          ].identificador,
                        area: item.identificador,
                        titulo: item.nombre,
                      });
                    } else
                      navigation.navigate("Contenido", {
                        botones: botones,
                        tipo: route.params.arbol.navegacion[
                          route.params.identificador
                        ].identificador,
                        asignatura:
                          route.params.arbol.navegacion[
                            route.params.identificador
                          ].asignaturas[route.params.asignatura].identificador,
                        area: item.identificador,
                        curso:
                          route.params.arbol.navegacion[
                            route.params.identificador
                          ].asignaturas[route.params.asignatura].cursos[
                            route.params.curso
                          ].identificador,
                        titulo: item.nombre,
                      });
                  }}
                  style={{ backgroundColor: "none" }}
                >
                  <Boton
                    imagen={item.logo}
                    texto={item.nombre}
                    identificador={item.identificador}
                    recurso={item.identificador}
                  ></Boton>
                </TouchableOpacity>
              );
            }
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default Area;
