import React, { useState } from "react";

export type CurrentUserContextType = {
  id: number;
  nombre: string;
  id_perfil: number;
  avatar: string;
};

export const ContextoGeneral = React.createContext<CurrentUserContextType>({
  id: 0,
  nombre: "",
  id_perfil: 0,
  avatar:''
});
