import { useEffect, useState } from "react";
import { Text, ScrollView, Pressable, View, StyleSheet } from "react-native";
import { recuperarFunciones } from "../data/consultas";
import ItemWeb from "./item";

const estilos = StyleSheet.create({
  etiqueta: {
    maxWidth: 100,
    backgroundColor: "#f7fffe",
    borderColor: "#6cc5bc",
    borderWidth: 2,
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
  },
  contenedor: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: 10,
    margin: 20,
  },
});

function Ejecutivas({ route, navigation }) {
  const [juegos, setJuegos] = useState([]);
  const [destino, setDestino] = useState("");

  navigation.setOptions({ title: route.params.titulo });
  useEffect(() => {
    const recuperarJuegos = async () => {
      const datos = await recuperarFunciones(
        route.params.curso,
        route.params.area
      );
      setJuegos(datos);
      setDestino(datos[0].guid + "?type=scorm");
    };

    recuperarJuegos();
  }, []);

  const setVisto = (i: number) => {
    juegos[i].visitado = true;
  };

  return (
    <>
      <View style={estilos.contenedor}>
        {juegos.map((item, index) => {
          return (
            <Pressable
              key={index}
              onPress={(e) => {
                setDestino(item.guid + "?type=scorm");
                setVisto(index);
              }}
            >
              <View style={estilos.etiqueta}>
                <View
                  id={"boton_" + index}
                  style={{
                    borderRadius: 10,
                    width: 20,
                    height: 20,
                    minWidth: 20,
                    backgroundColor:
                      index == 0 || item?.visitado == true
                        ? "#5fa35f"
                        : "#cc3745",
                    marginRight: 10,
                  }}
                ></View>
                <Text>{index + 1}</Text>
              </View>
            </Pressable>
          );
        })}
      </View>
      <View style={{ height: 700 }}>
        <ItemWeb gui={destino} />
      </View>
    </>
  );
}

export default Ejecutivas;
