import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Cargando from "../components/placeHolder";
import {
  useFonts,
  Grandstander_400Regular,
} from "@expo-google-fonts/grandstander";
const Contenido = ({ route, navigation }) => {
  const [items, setItems] = useState([]);
  const curso = route.params.curso;
  const area = route.params.area;
  const asignatura = route.params.asignatura;
  const tipo = route.params.tipo;

  let [fontsLoaded, fontError] = useFonts({
    Grandstander_400Regular,
  });
  navigation.setOptions({ title: route.params.titulo });
  if (!fontsLoaded && !fontError) {
    return null;
  }

  if (items.length < 1)
    axios
      .get(
        "https://edootek.com/wp-admin/admin-ajax.php?action=get_contenidos&area=" +
          area +
          "&curso=" +
          curso
      )
      .then((response) => {
        setItems(response.data.data);
      })
      .catch((e) => {});

  if (items.length < 1) return <Cargando />;
  else
    return (
      <ScrollView style={{ marginBottom: 80 }}>
        {items.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              onPress={() => {
                navigation.navigate("Recurso", {
                  contenido: item.identificador,
                  titulo: item.nombre,
                  curso: curso,
                  area: area,
                  asignatura: asignatura,
                  tipo: tipo,
                });
              }}
            >
              <View
                style={{
                  backgroundColor: "#edf9fc",
                  borderRadius: 10,
                  marginLeft: 40,
                  marginRight: 40,
                  marginBottom: 10,
                  marginTop: 10,
                  padding: 10,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  borderColor: "#44879980",
                  borderWidth: 2,
                  borderStyle: "solid",
                }}
              >
                <View
                  style={{
                    borderRadius: 25,
                    width: 50,
                    height: 50,
                    minWidth: 50,
                    backgroundColor: item.color,
                    marginRight: 10,
                  }}
                ></View>
                <Text
                  style={{
                    fontFamily: "Grandstander_400Regular",
                    color: "#448799",
                    width: "80%",
                  }}
                >
                  {item.nombre}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    );
};

export default Contenido;
