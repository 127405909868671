import { View, Text, SafeAreaView, ScrollView, Pressable } from "react-native";
import { useEffect, useState } from "react";
import ItemWeb from "./item";
import axios from "axios";
import Cargando from "../components/placeHolder";

const Calculo = ({ route, navigation }) => {
  const [categorias, setCategorias] = useState([]);
  const [cargado, setCargado] = useState(false);
  const [uri, setUri] = useState("");
  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    if (!cargado) {
      cargarCategoria("categorias_calculo", 0);
    }
  }, [categorias]);
  const cargarCategoria = (accion: string, categoria: number) => {
    setUri("");
    setCargando(true);
    let data = new FormData();
    data.append("action", accion);
    data.append("cat", categoria.toString());
    axios
      .post("https://edootek.com/wp-admin/admin-ajax.php", data, {
        headers: {
          Accept: "*/*",
        },
      })
      .then((response) => {
        setCategorias(response.data.data);
        setCargado(true);
        setCargando(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navegarPreguntas = (id: number) => {
    setUri(
      "https://edootek.com/calculo-mental/calmen-sumas/?type=scorm&preguntas=" +
        id
    );
  };
  if (cargando) {
    return (
      <SafeAreaView>
        <Cargando />
      </SafeAreaView>
    );
  }

  if (uri == "") {
    return (
      <SafeAreaView>
        <ScrollView>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 80,
              marginTop: 40,
              width: "100%",
              gap: 20,
            }}
          >
            {categorias[0]?.id_padre > 0 ? (
              <Pressable
                onPress={() => {
                  cargarCategoria("categorias_calculo", 0);
                }}
              >
                <Text
                  style={{
                    fontFamily: "Grandstander_400Regular",
                    color: "#448799",
                    width: 250,
                  }}
                >
                  {" "}
                  ← Otras operaciones
                </Text>
              </Pressable>
            ) : (
              <></>
            )}
            {categorias.map((item, index) => {
              let ejemplo =
                item.ejemplo && item.id_padre > 0
                  ? "\n\nEjemplo: " + item.ejemplo
                  : "";
              return (
                <Pressable
                  key={index}
                  onPress={() => {
                    if (item.id_padre < 1) {
                      cargarCategoria("categorias_calculo", item.id);
                    } else {
                      navegarPreguntas(item.id);
                    }
                  }}
                >
                  <View
                    style={{
                      backgroundColor: "#edf9fc",
                      borderRadius: 10,
                      marginBottom: 10,
                      marginTop: 10,
                      padding: 10,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      borderColor: "#44879980",
                      borderWidth: 2,
                      borderStyle: "solid",
                      width: 250,
                    }}
                  >
                    <View
                      style={{
                        borderRadius: 25,
                        width: 50,
                        height: 50,
                        minWidth: 50,
                        backgroundColor: item.color,
                        marginRight: 10,
                      }}
                    ></View>
                    <Text
                      style={{
                        fontFamily: "Grandstander_400Regular",
                        color: "#448799",
                        width: "80%",
                      }}
                    >
                      {item.nombre}
                      {ejemplo}
                    </Text>
                  </View>
                </Pressable>
              );
            })}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  } else {
    return (
      <View style={{ height: "100%" }}>
        <Pressable
          onPress={() => {
            cargarCategoria("categorias_calculo", 0);
          }}
        >
          <Text
            style={{
              fontFamily: "Grandstander_400Regular",
              color: "#448799",
              width: "100%",
              textAlign: "center",
              margin: 10,
            }}
          >
            {" "}
            ← Otras operaciones
          </Text>
        </Pressable>
        <ItemWeb gui={uri} />
      </View>
    );
  }
};

export default Calculo;
