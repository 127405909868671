import { View,Text } from "react-native";

const RadioButton = (props:{selected:boolean,texto:string})=>{
    return(
        <View style={{flexDirection:'row',margin:10}}>
            <View
                style={{
                    borderWidth:2,
                    borderColor:'#60acac',
                    width:20,
                    height:20,
                    borderRadius:10,
                    alignItems:"center",
                    justifyContent:'center'
                }}
            >
                {
                    props.selected ?
                    <View
                        style={{
                            width:10,
                            height:10,
                            borderRadius:5,
                            backgroundColor:'#60acac'
                        }}
                    >
                    </View>
                    :null
                }
            </View>
            <Text style={{marginLeft:10}}>{props.texto}</Text>
        </View>
    )
}

export default RadioButton; 