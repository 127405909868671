"use client";
import WebView from "react-native-webview";
import { useState, useEffect } from "react";
import { Platform, ActivityIndicator } from "react-native";
import * as React from "react";

const ItemWeb = (props) => {
  /*const [perfil, setPerfil] = useState(props.perfil);
  const [usuario, setusuario] = useState(props.usuario);
  const [idItem, setId] = useState(props.item);
  const [tipo, setTipo] = useState(props.tipo);*/
  const [url, setURL] = useState("");
  const [listo, setListo] = useState(false);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    setURL(props.gui);
    if (props?.scroll) {
      setScroll(props?.scroll);
    }
  });

  return Platform.OS == "web" ? (
    <>
      <ActivityIndicator style={{ display: listo ? "none" : "flex" }} />

      <iframe
        src={url}
        style={estilo}
        onLoad={() => {
          setListo(true);
        }}
        scrolling={scroll ? "yes" : "no"}
      />
    </>
  ) : (
    <WebView
      source={{ uri: url }}
      style={{ width: "100%", height: 700, borderWidth: 0 }}
      onLoad={() => {}}
      scrollEnabled={scroll}
    />
  );
};

const estilo = {
  margin: 0,
  height: 700,
  border: "none",
  flex: 1,
};

export default ItemWeb;
