import {StyleSheet} from 'react-native';

const global = StyleSheet.create({
  general:{
    fontFamily:'sans-serif',
    backgroundColor:'#CF386D',
    tintColor:'#fff',
    textAlign:'center',
  },
  center:{
    justifyContent:'center',
    alignItems:'center'
  },
  container: {
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection:'row'
  },
  botonImagenContainer:{
    padding:20,
    backgroundColor:'#CF386D',
    margin:20,
    flexDirection:'column',
    textAlign:'center',
    borderRadius:15,
    flexDirection:'row'
  },
  boton:{
    textAlign:'center',
    
    justifyContent:'center',
    alignItems:'center'
    
  },
  imagenBoton:{
    position:'relative',
    width:90,
    height:90,
    margin:'auto'
  },
  flex:{
    flexDirection:'row',
    flexWrap:'wrap'
  }

})

export default global;