import { View, Animated, ActivityIndicator } from "react-native";
import { useEffect, useState } from "react";
const Cargando = () => {
  const animacion = new Animated.Value(0);

  const iniciaAnimacion = () => {
    animacion.setValue(0);
    Animated.loop(
      Animated.sequence([
        Animated.timing(animacion, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: false,
        }),
        Animated.timing(animacion, {
          toValue: 0,
          duration: 1000,
          useNativeDriver: false,
        }),
      ])
    ).start();
  };

  const iterador = 5;
  const cajas = [];
  for (let i = 0; i < iterador; i++) {
    cajas.push(
      <View
        style={{
          width: 150,
          backgroundColor: "#cfcfcf",
          height: 40,
          margin: 10,
          opacity: 1,
        }}
      ></View>
    );
  }
  const [parpadeando, setParpadeando] = useState(true);
  useEffect(() => {
    if (parpadeando) {
      iniciaAnimacion();
    }
  }, []);
  /*return(
        <Animated.View style={{opacity:animacion}}>
       <View style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-between',alignItems:'center',margin:20}}>
        {cajas}
       </View>
       </Animated.View>
    )*/

  return (
    <View
      style={{
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
      }}
    >
      <ActivityIndicator size="large" color="#CF386D" />
    </View>
  );
};

export default Cargando;
