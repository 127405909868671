import { useState } from "react";
import Boton from "../components/boton";
import Post from "../components/post";
import { ScrollView, SafeAreaView, View, Pressable, Text } from "react-native";
import { recuperarReadingAjax } from "../data/consultas";
import Cargando from "../components/placeHolder";
function Reading({ route, navigation }) {
  navigation.setOptions({ title: "Reading and Listenning" });
  const [niveles, setNiveles] = useState([
    {
      nombre: "Nivel básico",
      icono: "https://edootek.com/wp-content/uploads/2023/07/basico.png",
      identificador: "D735",
    },
    {
      nombre: "Nivel medio",
      icono: "https://edootek.com/wp-content/uploads/2023/07/medio.png",
      identificador: "D835",
    },
    {
      nombre: "Nivel alto",
      icono: "https://edootek.com/wp-content/uploads/2023/07/avanzado.png",
      identificador: "D896",
    },
  ]);

  const [paginas, setPaginas] = useState([]);
  const [cargando, setCargando] = useState(false);

  const recuperarLectora = async (contenido: string) => {
    const items = await recuperarReadingAjax(contenido);
    setCargando(false);
    setPaginas(items);
  };

  if (cargando) {
    return (
      <SafeAreaView>
        <Cargando />
      </SafeAreaView>
    );
  }
  if (paginas.length > 0)
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: 150,
          backgroundColor: "#f2f2f2",
        }}
      >
        <Pressable
          onPress={() => {
            setPaginas([]);
          }}
          style={{ margin: 10, width: "100%" }}
        >
          <Text
            style={{
              fontFamily: "Grandstander_400Regular",
              color: "#448799",
              width: "100%",
              textAlign: "center",
              margin: 10,
            }}
          >
            ← Otros niveles
          </Text>
        </Pressable>
        {paginas.map((pagina) => {
          return (
            <Pressable
              onPress={() => {
                navigation.navigate("Pagina", {
                  url: pagina.url + "?type=scorm",
                  titulo: pagina.titulo,
                });
              }}
            >
              <Post imagen={pagina.imagen} titulo={pagina.titulo} />
            </Pressable>
          );
        })}
      </View>
    );
  else
    return (
      <SafeAreaView>
        <ScrollView>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 120,
              backgroundColor: "#f2f2f2",
            }}
          >
            {niveles.map((item, index) => {
              return (
                <Pressable
                  key={index}
                  onPress={() => {
                    setCargando(true);
                    recuperarLectora(item.identificador);
                  }}
                >
                  <Boton
                    imagen={item.icono}
                    texto={item.nombre}
                    identificador="111"
                    recurso="000"
                  />
                </Pressable>
              );
            })}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
}
export default Reading;
