import {
  ScrollView,
  SafeAreaView,
  View,
  TouchableOpacity,
  Text,
} from "react-native";
import Boton from "../components/boton";
import { useState } from "react";
const Curso = ({ route, navigation }) => {
  const [recurso, setRecurso] = useState(route.params.identificador);
  const [asignatura, setAsignatura] = useState(route.params.asignatura);
  const [botones, setBotones] = useState(
    route.params.botones[asignatura].cursos
  );
  navigation.setOptions({ title: route.params.botones[asignatura].nombre });

  return (
    <SafeAreaView>
      <ScrollView>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f2f2f2",
          }}
        >
          {botones.map(
            (
              item: { nombre: string; identificador: string; logo: string },
              index: number
            ) => {
              return (
                <TouchableOpacity
                  key={index}
                  onPress={() => {
                    if (recurso == 3) {
                      navigation.navigate("Videos", {
                        asignatura:
                          route.params.botones[asignatura].identificador,
                        curso: item.identificador,
                        titulo: item.nombre,
                      });
                    } else {
                      navigation.navigate("Area", {
                        botones: botones,
                        identificador: recurso,
                        asignatura: asignatura,
                        curso: index,
                      });
                    }
                  }}
                  style={{ backgroundColor: "none" }}
                >
                  <Boton
                    imagen={item.logo}
                    texto={item.nombre}
                    identificador={item.identificador}
                    recurso={item.identificador}
                  ></Boton>
                </TouchableOpacity>
              );
            }
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default Curso;
