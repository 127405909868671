import {
  ScrollView,
  SafeAreaView,
  View,
  TouchableOpacity,
  Text,
} from "react-native";
import Asignatura from "./asignatura";
import Boton from "../components/boton";
import { useState, useEffect } from "react";
import Cargando from "../components/placeHolder";

function Home({ navigation, route }) {
  const [botones, setBotones] = useState(route.params.arbol.navegacion);
  const [nivel, setNivel] = useState(1);
  const [recurso, setRecurso] = useState({
    recurso: 0,
    asignatura: 0,
    curso: "",
    area: "",
    contenido: "",
  });

  const actualizarBotones = (quien: string, index: number) => {
    //Sacar los botones del arbol

    setBotones([]);
    setTimeout(() => {
      switch (nivel) {
        case 1:
          setBotones(route.params.arbol.navegacion[index].asignaturas);
          setRecurso({
            recurso: index,
            asignatura: 0,
            curso: "",
            area: "",
            contenido: "",
          });
          break;
        case 2:
          setBotones(
            route.params.arbol.navegacion[recurso.recurso].asignaturas[index]
              .cursos
          );
          setRecurso({
            recurso: recurso.recurso,
            asignatura: index,
            curso: "",
            area: "",
            contenido: "",
          });
          break;
      }
    }, 1000);

    setNivel(nivel + 1);
  };

  if (botones.length > 0)
    return (
      <SafeAreaView>
        <ScrollView>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 80,
              backgroundColor: "#f2f2f2",
            }}
          >
            {botones.map(
              (
                item: { nombre: string; identificador: string; logo: string },
                index: number
              ) => {
                return (
                  <TouchableOpacity
                    key={index}
                    onPress={() => {
                      switch (item.nombre) {
                        case "Cálculo mental": {
                          navigation.navigate("Calculo", {
                            botones: botones,
                            identificador: index,
                            recurso: item.identificador,
                          });
                          break;
                        }
                        case "Comprension lectora":
                          navigation.navigate("Comprension", {
                            botones: botones,
                            identificador: index,
                            recurso: item.identificador,
                          });
                          break;
                        case "Reading and listenning":
                          navigation.navigate("Reading", {
                            botones: botones,
                            identificador: index,
                            recurso: item.identificador,
                          });
                          break;

                        case "Cuentos con valores":
                          navigation.navigate("Cuentos", {
                            botones: botones,
                            identificador: index,
                            recurso: item.identificador,
                          });
                          break;
                        default:
                          navigation.navigate("Asignatura", {
                            botones: botones,
                            identificador: index,
                            recurso: item.identificador,
                          });
                          break;
                      }
                    }}
                    style={{ backgroundColor: "none" }}
                  >
                    <Boton
                      imagen={item.logo}
                      texto={item.nombre}
                      identificador={item.identificador}
                      recurso={item.identificador}
                    ></Boton>
                  </TouchableOpacity>
                );
              }
            )}
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  else
    return (
      <SafeAreaView>
        <Cargando />
      </SafeAreaView>
    );
}

export default Home;
