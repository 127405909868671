import { Image, View } from "react-native";
import { TouchableOpacity, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
const Menu = ({
  pagina1,
  pagina2,
  pagina3,
}: {
  pagina1: never;
  pagina2: never;
  pagina3: never;
}) => {
  const navigation = useNavigation();
  return (
    <View
      style={{
        backgroundColor: "#fcfcfc",
        width: "100%",
        position: "fixed",
        bottom: 0,
        height: 80,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 20,
        flexDirection: "row",
      }}
    >
      <TouchableOpacity
        onPress={() => {
          navigation.navigate(pagina1);
        }}
      >
        <Image source={require("../assets/home.png")} style={estilo.icono} />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => {
          navigation.navigate(pagina3);
        }}
      >
        <Image source={require("../assets/profile.png")} style={estilo.icono} />
      </TouchableOpacity>
    </View>
  );
};

const estilo = StyleSheet.create({
  icono: {
    width: 40,
    height: 40,
  },
});

export default Menu;
